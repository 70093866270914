<template>
  <div id="invoice">
    <!-- breadCrumb -->
    <v-breadcrumbs :items="breadcrumbList" divider="/">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <!-- breadCrumb -->
    <!-- view mode -->
    <div class v-if="!editMode && $router.currentRoute.params.id">
      <v-row>
        <v-col cols="12" md="4" class="title-col">
          <div class="title">
            <h1>{{ $t("Transaction") }}</h1>
          </div>
        </v-col>
        <v-col cols="12" md="4" class>
          <div class="text-center">
            <v-icon
              v-if="printTransactions"
              color="#7297FF"
              @click="printTransaction($router.currentRoute.params.id)"
              large
              style="cursor: pointer"
              :title="$t('Print')"
              >print</v-icon
            >

            <v-icon
              color="#7297FF"
              class="ui-btn submit"
              v-if="
                id &&
                confirmed == true &&
                editMode == false &&
                transaction.reversed != 'true' &&
                !transaction.reversed
              "
              @click.prevent="reverseTransaction"
              :title="$t('Reverse')"
              >cached</v-icon
            >

            <v-btn
              color="#7297FF"
              class="ui-btn submit"
              v-if="
                id &&
                confirmed == false &&
                editMode == false &&
                confirmTransactions
              "
              @click.prevent="confirmTransaction"
              >{{ $t("Confirm") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <div class="text-right">
            <v-icon
              color="#7297FF"
              style="cursor: pointer"
              @click="openEditMode"
              v-if="!editMode && !confirmed && editTransactions"
              >mdi-pencil</v-icon
            >
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" class="info-list">
          <v-row>
            <v-col cols="12" md="3">
              <p class="value">
                <strong>{{ $t("Transaction ID") }}:</strong>
                {{ transaction.transaction_id }}
              </p>
            </v-col>
            <v-col cols="12" md="3">
              <p class="value">
                <strong>{{ $t("Transaction Date") }}:</strong>
                {{ transaction.transaction_date }}
              </p>
            </v-col>
            <v-col cols="12" md="3">
              <p class="value">
                <strong>{{ $t("Partners") }}:</strong>
                {{ transaction.partner }}
              </p>
            </v-col>
            <v-col cols="12" md="2">
              <p class="value">
                <strong>{{ $t("Warehouse") }}:</strong>
                {{ transaction.warehouse }}
              </p>
            </v-col>
            <v-col cols="12" md="1">
              <p class="value">
                <strong>{{ $t("Type") }}</strong>
                {{ transaction.type }}
              </p>
            </v-col>
            <v-col cols="12" md="12">
              <p class="value">
                <strong>{{ $t("Notes") }}:</strong>
                {{ transaction.notes }}
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <hr class="basic" />

      <v-row
        v-if="
          transaction.parent != null &&
          transaction.reversed == true &&
          !reversed
        "
      >
        <v-col cols="12">
          <center>
            <h3 class="main-color">{{ $t("Reversed From") }}:</h3>
          </center>
        </v-col>
        <v-col cols="12" md="3">
          <p class="value">
            <strong>{{ $t("Transaction ID") }}:</strong>
            {{ transaction.parent.transaction_id }}
          </p>
        </v-col>
        <v-col cols="12" md="3">
          <p class="value">
            <strong>{{ $t("Transaction Date") }}:</strong>
            {{ transaction.parent.transaction_date }}
          </p>
        </v-col>
        <v-col cols="12" md="3">
          <p class="value">
            <strong>{{ $t("Partners") }}:</strong>
            {{ transaction.parent.partner }}
          </p>
        </v-col>
        <v-col cols="12" md="3">
          <p class="value">
            <strong>{{ $t("Warehouse") }}:</strong>
            {{ transaction.parent.warehouse }}
          </p>
        </v-col>
      </v-row>
      <v-divider
        v-if="transaction.parent != null && reversed == true"
      ></v-divider>
      <v-row>
        <v-col cols="12" md="12">
          <v-data-table
            :options.sync="options"
            :headers="headers"
            :items="transaction.items"
            :items-per-page="5"
            class="elevation-1 level1"
            loading-text="Loading... Please wait"
            hide-default-footer
          >
            <template v-slot:item.actions="{ item }" v-if="!editMode">
              <v-icon
                v-if="item.barcode"
                @click="printbarcode(item.barcode, item.name)"
                :title="$t('Print Barcode')"
                >view_array</v-icon
              >
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <hr class="second" />

      <v-row>
        <v-col cols="4" md="4">
          <p class="stats">
            <strong>{{ $t("Total Before Tax") }}:</strong>
            {{ transaction.total_before_tax }}
          </p>
        </v-col>
        <v-col cols="4" md="4">
          <p class="stats">
            <strong>{{ $t("Tax Amount") }}:</strong>
            {{ transaction.tax_Amount }}
          </p>
        </v-col>
        <v-col cols="4" md="4">
          <p class="stats">
            <strong>{{ $t("Total") }}:</strong>
            {{ transaction.total }}
          </p>
        </v-col>
      </v-row>
    </div>
    <!-- edit mode -->
    <div class="edit" v-if="editMode">
      <v-form ref="form" v-model="valid">
        <!-- <v-row>
      <v-col cols="12" class="title-col">
        <div class="title">
          <h1>Invoice</h1>
        </div>
      </v-col>
        </v-row>-->
        <v-row>
          <v-col cols="12" md="12" class="info-list">
            <v-row>
              <v-col cols="6" md="3">
                <p class="value">
                  <strong class="custom-strong"
                    >{{ $t("Transaction ID") }}:</strong
                  >
                  <v-text-field
                    v-model.trim="editedItem.transaction_id"
                    solo-inverted
                    disabled
                  ></v-text-field>
                </p>
              </v-col>
              <v-col cols="12" md="3">
                <p class="value">
                  <strong class="custom-strong"
                    >{{ $t("Transaction Date") }}:</strong
                  >

                  <!-- :rules="[
                      validationRules.required,
                  ]"-->
                  <v-datetime-picker
                    solo
                    v-model="editedItem.transaction_date"
                    dateFormat="yyyy-MM-dd"
                    timeFormat="HH:mm:ss"
                    outlined
                  ></v-datetime-picker>
                </p>
              </v-col>
              <v-col cols="12" md="2">
                <p class="value">
                  <strong class="custom-strong">{{ $t("partners") }}:</strong>
                  <v-autocomplete
                    :label="$i18n.t('Partner')"
                    :items="getAllpartners"
                    @change="hideLabel = true"
                    item-text="name"
                    item-value="id"
                    :rules="[validationRules.required]"
                    v-model="editedItem.partner_id"
                    solo
                  ></v-autocomplete>
                </p>
                <div
                  v-if="validation_errors.partner_id"
                  style="margin-top: -20px"
                >
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.partner_id"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" md="2">
                <p class="value">
                  <strong class="custom-strong">{{ $t("Warehouse") }}:</strong>
                  <v-autocomplete
                    :label="$i18n.t('Warehouse')"
                    :items="allwarehouses"
                    :rules="[validationRules.required]"
                    @change="
                      hideLabel = true && getItems(editedItem.warehouse_id)
                    "
                    item-text="name"
                    item-value="id"
                    v-model="editedItem.warehouse_id"
                    solo
                  ></v-autocomplete>
                </p>
                <div
                  v-if="validation_errors.warehouse_id"
                  style="margin-top: -20px"
                >
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.warehouse_id"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" md="1">
                <p class="value" style="margin-bottom: 0 !important">
                  <strong>{{ $t("Type") }}</strong>
                  <v-radio-group
                    v-model="editedItem.type"
                    @change="checkInOute"
                    :rules="[validationRules.required]"
                  >
                    <v-radio
                      :label="$i18n.t('IN')"
                      :value="'in'"
                      style="margin-bottom: 0"
                    ></v-radio>
                    <v-radio
                      :label="$i18n.t('OUT')"
                      :value="'out'"
                      v-if="reversed == true"
                    ></v-radio>
                  </v-radio-group>
                </p>
              </v-col>
              <v-col cols="12" md="12" class>
                <p class="value">
                  <strong class="custom-strong">{{ $t("Notes") }}:</strong>

                  <v-textarea
                    solo
                    auto-grow
                    rows="3"
                    v-model="editedItem.notes"
                  ></v-textarea>
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row
          v-if="transaction.parent != null && transaction.reversed == true"
        >
          <v-col cols="12">
            <center>
              <h3 class="main-color">{{ $t("Reversed From") }}:</h3>
            </center>
          </v-col>
          <v-col cols="6" md="3">
            <p class="value">
              <strong>{{ $t("Transaction ID") }}:</strong>
              {{ transaction.parent.transaction_id }}
            </p>
          </v-col>
          <v-col cols="12" md="3">
            <p class="value">
              <strong>{{ $t("Transaction Date") }}:</strong>
              {{ transaction.parent.transaction_date }}
            </p>
          </v-col>
          <v-col cols="12" md="3">
            <p class="value">
              <strong>{{ $t("Partners") }}:</strong>
              {{ transaction.parent.partner }}
            </p>
          </v-col>
          <v-col cols="12" md="3">
            <p class="value">
              <strong>{{ $t("Warehouse") }}:</strong>
              {{ transaction.parent.warehouse }}
            </p>
          </v-col>
        </v-row>
        <v-row v-if="reversed == true">
          <v-col cols="12">
            <center>
              <h3 class="main-color">{{ $t("Reversed From") }}:</h3>
            </center>
          </v-col>
          <v-col cols="6" md="3">
            <p class="value">
              <strong>{{ $t("Transaction ID") }}:</strong>
              {{ transaction.transaction_id }}
            </p>
          </v-col>
          <v-col cols="12" md="3">
            <p class="value">
              <strong>{{ $t("Transaction Date") }}:</strong>
              {{ transaction.transaction_date }}
            </p>
          </v-col>
          <v-col cols="12" md="3">
            <p class="value">
              <strong>{{ $t("Partners") }}:</strong>
              {{ transaction.partner }}
            </p>
          </v-col>
          <v-col cols="12" md="3">
            <p class="value">
              <strong>{{ $t("Warehouse") }}:</strong>
              {{ transaction.warehouse }}
            </p>
          </v-col>
        </v-row>
        <v-divider
          v-if="
            (transaction.parent != null && transaction.reversed == true) ||
            reversed == true
          "
        ></v-divider>

        <v-row>
          <v-col cols="12" md="12">
            <v-data-table
              :options.sync="options"
              :headers="headersEdit"
              :items="editedItem.items"
              class="elevation-1 level1"
              loading-text="Loading... Please wait"
              hide-default-footer
            >
              <template v-slot:item.item_id="{ item }">
                <p class="value" style="margin-top: 12px !important">
                  <!-- <strong>Code:</strong> -->
                  <v-autocomplete
                    :items="codes"
                    style="width: 250px"
                    @change="hideLabel = true && changeCat()"
                    item-text="code_name"
                    :rules="[validationRules.required]"
                    item-value="id"
                    v-model="item.item_id"
                    solo
                  ></v-autocomplete>
                </p>
              </template>

              <template v-slot:item.quantity="{ item }">
                <p class="value" style="margin-top: 12px !important">
                  <!-- <strong>Quantity:</strong> -->
                  <v-text-field
                    min="0"
                    :rules="[
                      validationRules.required,
                      validationRules.numericNotZero,
                    ]"
                    style="width: 150px"
                    v-model.trim="item.quantity"
                    solo
                    @keyup="calcitemTotal"
                  ></v-text-field>
                </p>
              </template>

              <template v-slot:item.price="{ item }">
                <p class="value" style="margin-top: 12px !important">
                  <!-- <strong>Price:</strong> -->
                  <v-text-field
                    style="width: 150px"
                    @keyup="calcitemTotal"
                    :rules="[
                      validationRules.required,
                      validationRules.numericNotZero,
                    ]"
                    min="0"
                    v-if="
                      editedItem.type == 'in' ||
                      item.item_id == '' ||
                      editedItem.type == ''
                    "
                    v-model.trim="item.price"
                    solo
                  ></v-text-field>

                  <v-text-field
                    style="width: 150px"
                    v-else
                    disabled
                    @keyup="calcitemTotal"
                    :rules="[
                      validationRules.required,
                      validationRules.numericNotZero,
                    ]"
                    min="0"
                    v-model.trim="item.price"
                    solo
                  ></v-text-field>
                </p>
              </template>
              <template v-slot:item.total="{ item }">
                <p class="value" style="margin-top: 12px !important">
                  <!-- <strong>Total:</strong> -->
                  <v-text-field
                    style="width: 150px"
                    disabled
                    v-model.trim="item.total"
                    solo
                  ></v-text-field>
                </p>
              </template>

              <template v-slot:item.barcode="{ item }">
                <p class="value" style="margin-top: 12px !important">
                  <!-- <strong>Total:</strong> -->
                  <v-text-field
                    v-model.trim="item.barcode"
                    append-icon="view_array"
                    @click:append="genreatBaracode(item)"
                    style="width: 150px"
                    v-if="
                      editedItem.type == 'in' ||
                      item.item_id == '' ||
                      editedItem.type == ''
                    "
                    solo
                  ></v-text-field>

                  <v-autocomplete
                    v-else
                    :items="item.barcode"
                    style="width: 250px"
                    @change="
                      hideLabel = true && changePrice(item, item.barcode)
                    "
                    v-model.trim="item.barcode"
                    item-text="bar"
                    :rules="[validationRules.required]"
                    item-value="bar"
                    solo
                  ></v-autocomplete>
                </p>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-icon color="#7297FF" @click="deleteRow(item)">delete</v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" class="text-center">
            <v-icon color="#7297FF" @click="AddRow" large>add_circle</v-icon>
          </v-col>
        </v-row>

        <hr class="second" />

        <v-row>
          <v-col cols="4" md="4">
            <p class="stats">
              <strong>{{ $t("Total Before Tax") }}:</strong>
              {{ editedItem.total_before_tax }}
            </p>
          </v-col>
          <v-col cols="4" md="4">
            <p class="stats">
              <strong>{{ $t("Tax Amount") }}:</strong>
              {{ editedItem.tax_Amount }}
            </p>
          </v-col>
          <v-col cols="4" md="4">
            <p class="stats">
              <strong>{{ $t("Total") }}:</strong>
              {{ editedItem.total }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <div class="btns text-right">
              <!-- <button class="ui-btn submit">Save</button> -->
              <!-- <v-btn class="ma-2" outlined color="#7297FF" large>Cancel</v-btn> -->
              <v-btn
                class="modal-btn-cancel ma-2"
                @click="goToviewMode"
                large
                >{{ $t("Cancel") }}</v-btn
              >

              <v-btn
                depressed
                v-if="id"
                color="#7297FF"
                @click="update()"
                large
                style="color: #fff"
                :loading="loading"
                :disabled="loading"
                >{{ $t("Update") }}</v-btn
              >
              <v-btn
                depressed
                v-else
                color="#7297FF"
                @click="Save()"
                large
                style="color: #fff"
                :loading="loading"
                :disabled="loading"
                >{{ $t("Save") }}</v-btn
              >
            </div>

            <!-- <v-icon color="#7297FF" large>save</v-icon> -->
          </v-col>
        </v-row>
      </v-form>
    </div>
    <!-- snackbar -->
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">{{ snackText }}</span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
    <div
      id="barcode_div"
      style="
        width: 152px !important;
        font-size: 7px;
        over-flow: hidden;
        visibility: hidden;
      "
    >
      <div class="text" style="margin: 0 !important; padding: 0 !important">
        <span
          style="display: inline-block; margin-right: 20px; margin-left: 5px"
          >{{ item_name }}</span
        >

        <span style="display: inline-block">{{ schoolname }}</span>
      </div>

      <barcode
        style="margin: 0 auto !important; max-width: 100% !important"
        v-bind:value="barcode"
        :options="{ lineColor: '#007bff' }"
        tag="svg"
      ></barcode>
    </div>
    <!-- <div id="barcode_div" class="svg-container">
      <p>
        {{ item_name }}
      </p>

      <p>
        {{ schoolname }}
      </p>

      <barcode
        class="svg-content"
        v-bind:value="barcode"
        :options="{ lineColor: '#007bff' }"
        tag="svg"
      ></barcode>
    </div>-->
  </div>
</template>

<script>
import axios from "axios";
// import CollectCashComponent from "../../components/modals/CollectCashComponent";
import ACL from "../../acl";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  name: "CreateWarehuseTransactions",
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      schoolname: localStorage.school_name,
      item_name: "",
      confirmed: false,
      id: null,
      headers: [
        { text: this.$i18n.t("Code"), value: "code_name" },
        {
          text: this.$i18n.t("Item Category"),
          value: "item_category",
          sortable: false,
        },
        { text: this.$i18n.t("Quantity"), value: "quantity", sortable: false },
        { text: this.$i18n.t("Price"), value: "price", sortable: false },

        { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
      ],
      headersEdit: [
        { text: this.$i18n.t("Code"), value: "item_id" },
        {
          text: this.$i18n.t("Item Category"),
          value: "item_category",
          sortable: false,
        },
        { text: this.$i18n.t("Quantity"), value: "quantity", sortable: false },
        { text: this.$i18n.t("Price"), value: "price", sortable: false },
        { text: this.$i18n.t("Barcode"), value: "barcode", sortable: false },
        { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
      ],
      // items: [],
      dateTimeValid: false,
      codes: [],
      editMode: false,
      options: {
        itemsPerPage: 100,
      },
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 3000,
      getAllpartners: [],
      allwarehouses: [],
      transaction: {
        id: "",
        transaction_id: "",
        transaction_date: "",
        partner_id: "",
        warehouse_id: "",
        type: "",
        notes: "",
        items: [],
        tax_Amount: "",
        total: "",
        total_before_tax: "",
      },
      editedItem: {
        id: "",
        transaction_id: "",
        transaction_date: "",
        partner_id: "",
        warehouse_id: "",
        type: "",
        notes: "",
        items: [],
        tax_Amount: "",
        total: "",
        total_before_tax: "",
      },
      taxPercentage: "",
      valid: true,
      validation_errors: [],
      printTransactions: ACL.checkPermission("print-transactions"),
      editTransactions: ACL.checkPermission("edit-transactions"),
      confirmTransactions: ACL.checkPermission("confirm-transactions"),
      reversed: false,
      characters: "a-z,A-Z,0-9,#",
      barcode: "",
    };
  },

  methods: {
    printbarcode(barcode, name) {
      console.log(barcode);
      this.barcode = barcode;
      this.item_name = name;
      setTimeout(() => {
        // Get HTML to print from element
        const prtHtml = $("#barcode_div").html();

        // Get all stylesheets HTML
        let stylesHtml = "";
        for (const node of [
          ...document.querySelectorAll('link[rel="stylesheet"], style'),
        ]) {
          stylesHtml += node.outerHTML;
        }

        // Open the print window
        const WinPrint = window.open(
          "",
          "",
          "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
        );

        WinPrint.document.write(`<!DOCTYPE html>
          <html>
            <head>
              ${stylesHtml}
            </head>
            <body>
              ${prtHtml}
            </body>
          </html>`);

        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        // WinPrint.close();
      }, 1500);
    },
    onFocus() {
      this.validation_errors = [];
    },
    genreatBaracode(item) {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let barcodess = "";
      // let password = "";
      let CharacterSetEnglishC = "";
      let CharacterSetEnglishS = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSetEnglishC += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSetEnglishS += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }
      //   if (charactersArray.indexOf("#") >= 0) {
      //     CharacterSet += "![]{}()%&*$#^<>~@|";
      //   }
      for (let i = 0; i < 4; i++) {
        barcodess += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
        barcodess += CharacterSetEnglishC.charAt(
          Math.floor(Math.random() * CharacterSetEnglishC.length)
        );
        barcodess += CharacterSetEnglishS.charAt(
          Math.floor(Math.random() * CharacterSetEnglishS.length)
        );
      }
      item.barcode = barcodess;
    },
    reverseTransaction() {
      this.reversed = true;
      if (this.editedItem.type == "in") {
        this.editedItem.type = "out";
      } else {
        this.editedItem.type = "in";
      }
      this.editedItem.transaction_date = "";
      this.editedItem.transaction_id = "";
      this.editedItem.notes = "";
      this.openEditMode();
    },
    confirmTransaction() {
      axios
        .get(
          this.getApiUrl + "/warehouses_transactions/confirm/" + this.id + "/1",
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((res) => {
          if (res.data.status.error == false) {
            this.confirmed = true;
          } else {
            this.snack = true;
            this.snackColor = "red";
            this.snackText = res.data.status.message;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goToviewMode() {
      this.editMode = false;
      if (this.id) {
        this.$router.push(
          {
            path: "/" + "warehouses_transactions/show/" + this.id,
          },
          () => {}
        );
      } else {
        this.$router.push(
          {
            path: "/" + "WarehouseTransaction",
          },
          () => {}
        );
      }
    },
    changeCat() {
      this.editedItem.items.forEach((item) => {
        item.item_category = "";
        this.codes.forEach((cat) => {
          if (item.item_id == cat.id) {
            item.item_category = cat.category_name;
            item.taxable = cat.taxable;
            // console.log(cat.barcode[0]);
            if (this.editedItem.type == "out") {
              item.barcode = cat.barcode[0];
            }
            // this.item.barcode
          }
        });
      });
    },
    // changePrice(item, barcode) {
    //   item,
    //     barcode.forEach((bar) => {
    //       // item.price = "";

    //       if (bar.bar == barcode) {
    //         item.price = bar.price;
    //       }
    //     });
    //   console.log("price");
    // },
    AddRow() {
      this.editedItem.items.push({
        index: this.editedItem.items.length,
        id: "",
        item_id: "",
        code_name: "",
        item_category: "",
        quantity: "",
        price: "",
        barcode: "",
        taxable: "",
        // total: "",
      });
    },
    IntializeDatePicker() {
      let _this = this;
      setTimeout(() => {
        $("#transactionDate").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.editedItem.transactionDate = dateH;
            } else {
              _this.editedItem.transactionDate = "";
            }
          },
        });
      });
    },
    getWarehouses() {
      axios
        .get(this.getApiUrl + "/warehouses/getAllWarehouses", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((res) => {
          if (res.data.status.error == false) {
            this.allwarehouses = res.data.data;
            if (
              this.allwarehouses.length == 1 &&
              this.editedItem.warehouse_id == ""
            ) {
              this.editedItem.warehouse_id = this.allwarehouses[0].id;
              this.getItems(this.editedItem.warehouse_id);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getpartners() {
      axios
        .get(this.getApiUrl + "/partners/getAllpartners?all", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((res) => {
          if (res.data.status.error == false) {
            this.getAllpartners = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getItems(id, out) {
      let type = "";
      if (out == "true" || this.editedItem.type == "out") {
        type = "?out=" + out;
      }
      axios
        .get(this.getApiUrl + "/warehouses/getWarehouseItems/" + id + type, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((res) => {
          if (res.data.status.error == false) {
            this.codes = res.data.data;
            this.item.barcode = res.data.data[0].bar;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkInOute() {
      if (this.editedItem.type == "out") {
        this.getItems(this.editedItem.warehouse_id, "true");
        // this.editedItem.items = [];
      } else if (this.editedItem.type == "in") {
        this.getItems(this.editedItem.warehouse_id, "false");
      }
    },
    deleteRow(item) {
      // console.log(item);

      if (item.id == "") {
        const index = item.index;
        this.editedItem.items = this.editedItem.items.filter((item) => {
          return item.index != index;
        });
      } else {
        axios
          .get(
            this.getApiUrl +
              "/warehouses_transactions/deleteTransactionItem/" +
              item.id,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((res) => {
            if (res.data.status.error == false) {
              const index = this.editedItem.items.findIndex(
                (x) => x.id === item.id
              );
              this.editedItem.items.splice(index, 1);
              // console.log(index);
              // this.editedItem.editedItem = this.editedItem.items.filter(
              //   (item) => {
              //     return item.index != index;
              //   }
              // );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.calcitemTotal();
    },
    calcitemTotal() {
      // this.editedItem.total = price * quantiy;
      this.editedItem.total_before_tax = 0;
      this.editedItem.tax_Amount = 0;
      this.editedItem.items.forEach((item) => {
        if (item.taxable) {
          this.editedItem.tax_Amount +=
            (item.quantity * item.price * this.taxPercentage) / 100;
        }
        if (item.quantity != "" && item.price != "")
          this.editedItem.total_before_tax += item.quantity * item.price;
      });
      this.editedItem.total =
        this.editedItem.tax_Amount + this.editedItem.total_before_tax;
    },
    getTaxAmount() {
      axios
        .get(this.getApiUrl + "/warehouses_transactions/getTaxAmount", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((res) => {
          if (res.data.status.error == false) {
            this.taxPercentage = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    Save() {
      // if (this.editedItem.transaction_date == "") {
      //   this.dateTimeValid = true;
      // } else {
      //   this.dateTimeValid = false;
      // }
      if (this.valid) {
        if (this.editedItem.items.length > 0) {
          this.loading = true;
          axios
            .post(
              this.getApiUrl + "/warehouses_transactions/store",
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.loading = false;
              if (response.data.status.error == false) {
                // this.router.push("/warehouses_transactions");
                // this.getSmtpSmsConfigrations();
                this.snack = true;
                if (response.data.status.message == "Success") {
                  this.snackColor = "green";
                } else {
                  this.snackColor = "red";
                }
                this.snackText = response.data.status.message;
                this.editMode = false;
                // this.$router.push(
                //   {
                //     path: "/warehouses_transactions/show/" + response.data.data.id
                //   },
                //   () => {}
                // );
                setTimeout(function () {
                  window.location.href =
                    "/warehouses_transactions/show/" + response.data.data.id;
                }, 2000);
              } else {
                this.snack = true;
                this.snackColor = "red";
                this.snackText = response.data.status.message;
              }
            });

          // this.closeAddEditDialog();
        } else {
          this.snack = true;
          this.snackColor = "red";
          this.snackText = this.$i18n.t("please add item");
        }
      } else {
        this.$refs.form.validate();
      }
    },
    update() {
      if (this.valid) {
        // if (this.editedItem.transaction_date == "") {
        //   this.dateTimeValid = true;
        // } else {
        //   this.dateTimeValid = false;
        // }
        let rev = "";
        if (this.reversed) {
          rev = "?is_reversed=" + this.reversed;
        }
        this.loading = true;
        axios
          .post(
            this.getApiUrl +
              "/warehouses_transactions/update/" +
              this.$router.currentRoute.params.id +
              rev,
            this.editedItem,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.loading = false;
            if (response.data.status.error == false) {
              // this.router.push("/warehouses_transactions");
              // this.getSmtpSmsConfigrations();
              this.snack = true;
              this.snackColor = "green";
              this.snackText = response.data.status.message;
              this.editMode = false;
              if (this.reversed || this.reversed == "true") {
                // this.editMode = false;
                // console.log("reversed");
                // this.$router.push(
                //   {
                //     path:
                //       "/warehouses_transactions/show/" + response.data.data.id
                //   },
                //   () => {}
                // );
                setTimeout(function () {
                  window.location.href =
                    "/warehouses_transactions/show/" + response.data.data.id;
                }, 2000);

                // this.reversed = false;
              } else {
                // this.show();
                this.confirmed = response.data.data.confirmed;
                // this.reversed = res.data.data.reversed;
                this.transaction = response.data.data;
                this.editedItem = { ...response.data.data };
                this.editedItem.transaction_date = new Date(
                  this.editedItem.transaction_date
                );
                if (this.$route.query.editMode && !this.confirmed) {
                  // this.editMode = true;
                  this.getItems(this.editedItem.warehouse_id);
                }
              }
            } else {
              this.snack = true;
              this.snackColor = "red";
              this.snackText = response.data.status.message;
            }
          });
        if (this.$route.query.editMode) {
          // this.editMode = false;
          this.$router.push(
            {
              path: "/" + "warehouses_transactions/show/" + this.id,
            },
            () => {}
          );
        }
        // this.closeAddEditDialog();
      } else {
        this.$refs.form.validate();
      }
    },
    show() {
      this.id = this.$router.currentRoute.params.id;
      if (this.$router.currentRoute.params.id) {
        axios
          .get(
            this.getApiUrl +
              "/warehouses_transactions/show/" +
              this.$router.currentRoute.params.id,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((res) => {
            if (res.data.status.error == false) {
              this.confirmed = res.data.data.confirmed;
              // this.reversed = res.data.data.reversed;
              this.transaction = res.data.data;
              this.editedItem = { ...res.data.data };
              this.editedItem.transaction_date = new Date(
                this.editedItem.transaction_date
              );
              if (this.$route.query.editMode && !this.confirmed) {
                // this.editMode = true;
                this.getItems(this.editedItem.warehouse_id);
              }
              // console.log(dt); // Gives Tue Mar 22 2016 09:30:00 GMT+0530 (IST)

              // dt.setTime(dt.getTime() + dt.getTimezoneOffset() * 60 * 1000);
              // console.log(dt); // Gives Tue Mar 22 2016 04:00:00 GMT+0530 (IST)
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    checkRoute() {
      if (this.$router.currentRoute.params.id) {
        this.editedItem.id = this.$router.currentRoute.params.id;
        this.transaction.id = this.$router.currentRoute.params.id;
        // this.headers = this.headers.filter(item => {
        //   return item.value != "actions";
        // });
        this.show();
      } else {
        this.editMode = true;
      }
    },
    openEditMode() {
      this.editMode = true;
      this.getItems(this.editedItem.warehouse_id, "true");
    },
    printTransaction(id) {
      axios
        .get(
          this.getApiUrl +
            "/warehouses_transactions/printWareHouseTransaction/" +
            id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((res) => {
          if (res.data.status.error == false) {
            let printLink = res.data.data;
            window.open(printLink, "_blank");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.checkRoute();
    this.getWarehouses();
    this.IntializeDatePicker();
    this.getpartners();
    this.getTaxAmount();
    if (!this.$router.currentRoute.params.id) {
      this.editedItem.transaction_date = new Date(Date.now());
    }
    if (this.$route.query.editMode && !this.confirmed) {
      this.editMode = true;
      // this.getItems(this.editedItem.warehouse_id);
    } else if (this.$route.query.editMode && this.confirmed) {
      this.editMode = false;
      this.$router.push(
        {
          path: "/" + "warehouses_transactions/show/" + this.id,
        },
        () => {}
      );
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
// .svg-container {
//   display: inline-block;
//   // position: relative;
//   width: 100%;
//   padding-bottom: 100%;
//   vertical-align: middle;
//   overflow: hidden;
// }
// .svg-container p {
//   width: 30%;
//   margin: 0;
//   padding: 0;
//   font-size: 10px;
//   // position: absolute;
//   top: 0;
//   left: 0;
//   display: inline-block;
// }
// .svg-content {
//   display: inline-block;
//   // position: absolute;
//   top: 0;
//   left: 0;
// }
// @media print {
//   #barcode_div {
//     width: 100px !important;
//   }
// }
.info-list {
  padding: 0 12px;
  .value {
    font-size: 13px;
  }
}
.paymentBtn {
  padding-top: 6px !important;
}
hr.basic {
  margin: 0;
}
hr.second {
  border: 2px solid $main-color;
  margin: 0 auto;
  background-color: $main-color;
}
.v-data-table th {
  height: 25px;
}

.stats {
  strong {
    color: $main-color;
  }
}

.calendar-icon {
  padding-right: 5px;
}
.due-date {
  color: #ff8972;
}

.title-col {
  padding-bottom: 0;
}
.print-col {
  padding: 0 12px;
}
#invoice .v-data-table th {
  height: 25px !important;
}
.custom-strong {
  display: block;
  margin-bottom: 6px;
}
</style>
